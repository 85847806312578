import debounce from './debounce';
import keycode from 'keycode';

let currentKeys = '';
let combinations = [];
let monitorKeys = false;

function testCombinations() {
  const matches = combinations.filter(comb => currentKeys == comb.keys);
  console.log(matches);
  matches.forEach(match => match.action());
  currentKeys = '';
}

const debouncedTest = debounce(testCombinations, 250);

document.onkeydown = e => {
  const target = keycode(e);
  if (target === 's') {
    monitorKeys = true;
    return;
  }
  if (monitorKeys) {
    currentKeys += target;
  }
  debouncedTest();
};

document.onkeyup = e => {
  if (keycode(e) === 's') {
    monitorKeys = false;
  }
};

export default function styleKeyCombination(keys, action) {
  combinations = combinations.filter(comb => comb.keys !== keys);
  combinations.push({ keys, action })
}

import getTypekit from './typekit.js';
import './index.scss';
import styleKeyCombination from './js/onKeyCombination';

let colorClasses = [
  'base-pink',
  'base-green',
  'base-blue',
  'pink',
  'blue',
  'green',
  'light-pink',
  'light-blue',
  'light-pink-blue',
  'light-blue-pink',
  'dark-pink-blue',
  'dark-blue-pink',
  'dark-blue-green',
  'dark-pink-green',
  'dark-green-blue',
  'dark-green-pink',
];
getTypekit(document);
let colorTimeout;
let lastClass = 'no-color';

function pickColor(cancelColor = 'no-color') {
  let color = colorClasses[Math.floor(Math.random() * colorClasses.length)]
  switchColor(color, cancelColor)
  colorTimeout = setTimeout(() => pickColor(color), 30000);
}

function switchColor(newClass, oldClass) {
  document.body.classList.remove(oldClass);
  document.body.classList.add(newClass);
  lastClass = newClass;
}

pickColor();

colorClasses.forEach((color, index) => {
  styleKeyCombination((index + 1).toString(), () => {
    clearTimeout(colorTimeout);
    switchColor(color, lastClass);
  })
});
